@import 'app/styles/common/component.scss';

.form-header {
  margin: 40px 0 0px;
  display: flex;
  align-items: center;
  position: relative;

  @include breakpoint('sm-down') {
    margin: 40px 0 0px 15px;
  }

  &__icon {
    position: absolute;
    left: -35px;
    font-size: pxToRem(22);
    color: $c-white;
  }

  &__title {
    margin: 0;
  }
}
