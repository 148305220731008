@import 'app/styles/common/component.scss';

.toogle-button {
  transition: all ease 0.2s;
  cursor: pointer;
  display: inline-block;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      &:nth-child(1) {
        margin-right: 5px;
      }
      &:nth-child(3) {
        margin-left: 5px;
      }
    }
  }

  &-contained {
    &-gray {
      color: $c-black;
      background-color: $c-alto;
      border-color: $c-alto;
      font-weight: bold;

      &:hover {
        background-color: $c-regalBlue;
        color: $c-white;
      }

      &:active {
        background-color: $c-regalBlue;
      }
    }

    &-chathams-blue {
      background-color: #4b6986;
      border-color: #4b6986;
      color: $c-white;

      &:hover {
        background-color: lighten(#4b6986, 15%);
        color: $c-white;
      }

      &:active {
        background-color: lighten(#4b6986, 15%);
        color: $c-white;
      }
    }

    &-violet {
      color: $c-white;
      background-color: $c-seance;
      border-color: $c-seance;
      font-weight: bold;

      &:hover {
        background-color: darken($c-seance, 5%);
      }

      &:active {
        background-color: darken($c-seance, 5%);
      }
    }

    &-blue {
      color: $c-white;
      background-color: $c-blue;
      border-color: $c-blue;

      &:hover {
        background-color: darken($c-blue, 5%);
      }

      &:active {
        background-color: $c-blue;
      }
    }

    &-blue-dark {
      color: $c-white;
      background-color: $c-prussianBlue;
      border-color: $c-prussianBlue;

      &:hover {
        color: $c-white;
        background-color: $c-btn-hoover;
      }

      &:active {
        color: $c-white;
        background-color: $c-btn-hoover;
      }
    }

    &-orange {
      color: $c-white;
      background-color: $c-orange;

      &:hover {
        background-color: darken($c-orange, 5%);
      }

      &:active {
        background-color: $c-orange;
      }
    }
    &-orange-secondary {
      color: $c-white;
      background-color: #f48329;
      border-color: #f48329;

      &:hover {
        color: $c-white;
        background-color: #f16323;
      }

      &:active {
        background-color: #f16323;
      }
    }
    &-gray-light {
      color: $c-gray-secondary;
      background-color: $c-gray-light;
      border-color: $c-gray-light;

      &:hover {
        background-color: darken($c-gray-light, 5%);
      }

      &:active {
        background-color: $c-gray-light;
      }
    }
    &-blue-light {
      color: $c-white;
      background-color: #0f74c7;
      border-color: #0f74c7;
      font-weight: 400;

      &:hover {
        color: $c-white;
        background-color: #0a5c9f;
      }

      &:active {
        background-color: #0a5c9f;
      }
    }
    &-green {
      color: $c-white;
      background-color: $c-green;
      border-color: $c-green;

      &:hover {
        background-color: darken($c-green, 5%);
      }

      &:active {
        background-color: $c-green;
      }
    }
  }
  &-text {
    &-blue {
      color: $c-white;
      background-color: transparent;
      border: transparent;
    }

    &-blue-dark {
      color: $c-white;
      background-color: transparent;
      border: transparent;
    }

    &-orange {
      color: $c-white;
      background-color: transparent;
      border: transparent;
    }
  }

  &-outline {
    color: $c-white;
    background-color: transparent;
    border-color: $c-white;
    border-style: solid;
    border-width: 2px;

    &-white {
      color: $c-white;
      border-color: $c-white;

      &:hover {
        color: $c-blue;
        background-color: $c-white;
      }

      &:active {
        border-color: rgba($c-blue, 0.5);
      }
    }

    &-blue {
      color: $c-blue;
      border-color: $c-blue;

      &:hover {
        color: $c-blue;
        background-color: $c-white;
      }

      &:active {
        border-color: rgba($c-blue, 0.5);
      }
    }

    &-blue-dark {
      color: $c-white;

      &:hover {
        border-color: $c-white;
      }

      &:active {
        border-color: $c-white;
      }
    }
    &-orange {
      color: $c-white;
      border-color: $c-orange;

      &:hover {
        background-color: $c-orange;
      }

      &:active {
        background-color: $c-orange;
      }
    }
    &-gray {
      color: $c-gray;
      border-color: $c-gray;

      &:hover {
        background-color: $c-orange;
        border-color: $c-orange;
        color: $c-white;
      }

      &:active {
        background-color: $c-orange;
      }
    }
  }

  &-round {
    &-none {
      border-radius: 0px;
    }
    &-light {
      border-radius: 4px;
    }
    &-medium-light {
      border-radius: 8px;
    }
    &-medium {
      border-radius: 14px;
    }
    &-strong {
      border-radius: 30px;
    }
  }

  &-big {
    font-size: pxToRem(20);
    padding: 15px 35px;
    border-width: 3px;
    @include breakpoint('lg-down') {
      padding: 10px 30x;
      font-size: pxToRem(18);
    }
    @include breakpoint('xs-down') {
      padding: 10px 20px;
    }
  }

  &-medium {
    font-size: pxToRem(16);
    padding: 12px 30px;
    border-width: 2px;
  }

  &-small {
    font-size: pxToRem(14);
    padding: 6px 20px;
    border-width: 1px;
  }

  &-block {
    display: block;
    width: 100%;
  }

  &-rounded {
    border-radius: 15px;
  }

  &-pdf {
    color: $c-gray;
    background-color: $c-athensGray;
    border: 1px solid $c-gray;
    font-size: pxToRem(17);
    width: 120px;
    height: 45px;
    padding: 8px 10px;
    font-weight: 600;
    border-radius: 10px;
    margin-left: 5px;

    span {
      display: flex;
      align-items: center;

      .pdf-icon {
        margin-right: 5px;
        font-size: pxToRem(27);
      }
      .download-icon {
        margin-left: 5px;
        font-size: pxToRem(27);
      }
    }
  }

  &-form {
    background-color: $c-white;
    border-color: $c-white;
    color: $c-blue-dark;
    font-weight: 600;
    margin: 30px 0 20px;
    width: 122px;
    padding: 5px 0;
    border-style: solid;

    &:hover {
      color: $c-blue-dark;
      background-color: $c-white;
    }

    &:active {
      color: $c-blue-dark;
      background-color: $c-white;
    }

    &-red {
      background-color: $c-brickRed;
      border-color: $c-brickRed;
      color: $c-white;
    }

    &-gray {
      background-color: #dddddd;
      border-color: #dddddd;
      color: $c-black;
    }

    &-blue-dark {
      color: $c-white;
      background-color: $c-prussianBlue;
      border-color: $c-prussianBlue;

      &:hover {
        color: $c-white;
        background-color: $c-btn-hoover;
      }

      &:active {
        color: $c-white;
        background-color: $c-btn-hoover;
      }
    }

    @include breakpoint('xs-down') {
      width: 100%;
      padding: 8px 0;
      margin: 30px 0 10px;
    }
  }

  &-composition {
    width: 160px;
    height: pxToRem(38);
    line-height: pxToRem(21);
    font-size: pxToRem(18);
    padding: 0 10px;
    color: $c-black;
    background-color: $c-white;
    border: 1px solid $c-doveGray;
    margin-bottom: 20px;
    font-weight: 500;
  }

  &--disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }

  &-no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
