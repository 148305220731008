.loader {
  width: 25px;
  margin: 150px auto;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: disappear 1s infinite linear alternate;
}
@keyframes disappear {
  0% {
    box-shadow: 40px 0 #000, -40px 0 #0002;
    background: #000;
  }
  33% {
    box-shadow: 40px 0 #000, -40px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 40px 0 #0002, -40px 0 #000;
    background: #0002;
  }
  100% {
    box-shadow: 40px 0 #0002, -40px 0 #000;
    background: #000;
  }
}
