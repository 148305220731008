@import "./variables.scss";

@function pxToRem($px, $remPx: 16) {
  @return ($px/$remPx) + rem;
}

@mixin breakpoint($point){
  $query-direction:"";
  $query-point:"";
  $query-size:"";

  @if str-index($point,  "-down"){
    $query-point:str-slice( $point, 0, str-length($point) - str-length("-down"));
    $query-direction:"max-width";
    $query-size:map-get($breakpoints , $query-point ) - 1px;
  }

  @media screen and (#{$query-direction}: #{$query-size}){
    @content;
  }
}