@import 'app/styles/common/component.scss';

.form {
  &__had-account {
    color: $c-white;
    font-size: pxToRem(16);
    .toogle-button {
      padding: 0 0 0 5px;
    }
  }

  .ant-drawer-content {
    background-color: $c-blue-dark;
    color: $c-white;
    .ant-drawer-header {
      background-color: $c-blue-dark;
      padding: 10px 20px;
    }
    .ant-drawer-header-title {
      display: flex;
      justify-content: flex-end;
      .ant-drawer-close {
        color: $c-white;
        font-size: 20px;
      }
    }

    &-wrapper {
      width: 530px !important;
    }
  }

  .ant-input-password,
  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper {
    background-color: $c-blue-dark;
    &:focus,
    &:active {
      border-color: none !important;
      box-shadow: none !important;
    }
  }

  .ant-form-item-control-input-content {
    margin: 0 auto;
    padding-bottom: 0px;

    .ant-input-status-error,
    .ant-input-affix-wrapper-status-error {
      background-color: transparent !important;
    }

    .ant-input,
    .ant-input-affix-wrapper,
    .ant-input-password {
      background-color: $c-blue-dark;
      border: none;
      border-bottom: 2px solid $c-white;
      color: $c-white;
      outline: 0;
      padding-bottom: 0px;
      padding-left: 5px;
      font-size: pxToRem(18);

      &:hover {
        background-color: transparent;
        border: none;
        border-bottom: 2px solid $c-white;
      }
      &:focus,
      &:active {
        border: none;
        border-bottom: 2px solid $c-white;
        border-color: none !important;
        box-shadow: none !important;
        outline: 0;

        &:hover {
          background-color: transparent;
          outline: 0;
        }
      }
      &::placeholder {
        color: $c-white !important;
        opacity: 1;
      }

      .ant-input {
        border: transparent;
      }
      .ant-input-suffix {
        .anticon {
          color: $c-white;
        }
      }
    }
  }
}
