@import 'app/styles/common/component.scss';

.typography {
  &-h1 {
    &--huge {
      font-size: 3.125rem;
      line-height: pxToRem(90);

      @include breakpoint('lg-down') {
        font-size: pxToRem(55);
        line-height: pxToRem(65);
      }
      @include breakpoint('md-down') {
        font-size: pxToRem(40);
        line-height: pxToRem(50);
      }
      @include breakpoint('xs-down') {
        font-size: pxToRem(32);
        line-height: pxToRem(40);
      }
    }
    &--regular {
      font-size: pxToRem(36);
      line-height: pxToRem(42);
      @include breakpoint('md-down') {
        font-size: pxToRem(32);
        line-height: pxToRem(36);
      }
      @include breakpoint('xs-down') {
        font-size: pxToRem(28);
        line-height: pxToRem(32);
      }
    }
  }

  &-h2 {
    &--huge {
      font-size: pxToRem(40);

      @include breakpoint('lg-down') {
        font-size: pxToRem(32);
      }
      @include breakpoint('md-down') {
        font-size: pxToRem(29);
      }
      @include breakpoint('xs-down') {
        font-size: pxToRem(25);
      }
    }
    &--regular {
      font-size: pxToRem(36);
      line-height: pxToRem(42);

      @include breakpoint('md-down') {
        font-size: pxToRem(32);
        line-height: pxToRem(36);
      }
      @include breakpoint('xs-down') {
        font-size: pxToRem(28);
        line-height: pxToRem(32);
      }
    }
    &--large {
      font-size: pxToRem(30);
      @include breakpoint('md-down') {
        font-size: pxToRem(26);
      }
      @include breakpoint('xs-down') {
        font-size: pxToRem(20);
      }
    }
    &--medium {
      font-size: pxToRem(24);
    }
    &--small {
      font-size: pxToRem(20);
      @include breakpoint('md-down') {
        font-size: pxToRem(18);
      }
    }
  }

  &-h3 {
    &--huge {
      font-size: pxToRem(36);

      @include breakpoint('md-down') {
        font-size: pxToRem(33);
      }
      @include breakpoint('xs-down') {
        font-size: pxToRem(30);
      }
    }
    &--large {
      font-size: pxToRem(30);
      line-height: 1.2;

      @include breakpoint('md-down') {
        font-size: pxToRem(33);
      }
      @include breakpoint('xs-down') {
        font-size: pxToRem(26);
        line-height: 1.1;
      }
    }
    &--regular {
      font-size: pxToRem(24);
      @include breakpoint('md-down') {
        font-size: pxToRem(18);
      }
    }
  }

  &-h4 {
    &--regular {
      font-size: pxToRem(24);
      @include breakpoint('xl-down') {
        font-size: pxToRem(20);
      }
    }
  }

  &-h5 {
    &--regular {
      font-size: pxToRem(22);
      @include breakpoint('md-down') {
        font-size: pxToRem(20);
      }
    }
  }

  &-h6 {
    &--regular {
      font-size: pxToRem(22);
    }
  }

  &-p {
    &--large {
      font-size: pxToRem(18);
      line-height: pxToRem(30);
      @include breakpoint('md-down') {
        font-size: pxToRem(16);
      }
      @include breakpoint('xs-down') {
        font-size: pxToRem(14);
      }
    }
    &--regular {
      font-size: pxToRem(16);
      line-height: pxToRem(26);
      @include breakpoint('xl-down') {
        font-size: pxToRem(15);
      }
      @include breakpoint('xs-down') {
        font-size: pxToRem(13);
      }
    }
    &--small {
      font-size: pxToRem(12);
      line-height: pxToRem(22);
    }
  }

  &--primary {
    color: $c-regalBlue;
  }
  &--secondary {
    color: $c-blueZodiac;
  }
  &--black {
    color: $c-black;
  }
  &--white {
    color: $c-white;
  }
  &--bold {
    font-weight: 600;
  }
}
