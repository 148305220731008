$c-blue: #0f74c7;
$c-blue-dark: #0b1f3a;
$c-blue-light: #0f74c7;
$c-orange: #e16b38;
$c-orange-secondary: #f48329;
$c-white: #fff;
$c-gray: #7c7c7c;
$c-green: #4c9800;
$c-gray-light: #f2f2f2;
$c-gray-secondary: #526372;
$c-black: #000;
$c-btn-hoover: #004279;

// adobe
$c-white: #fff;
$c-concrete: #f2f2f2;
$c-gallery: #efefef; //btn primary text
$c-bombay: #b5bbc3;
$c-alto: #e0e0e0;
$c-athensGray: #f1f3f6;
$c-gray: #8b8b8b;
$c-silver: #cccccc;
$c-doveGray: #707070;
$c-mineShaft: #333333;
$c-flamePea: #e16b38;
$c-cardinal: #d01f3c;
$c-brickRed: #c63140;
$c-apple: #3cba45;
$c-fern: #63b655;
$c-chathamsBlue: #174275;
$c-regalBlue: #004279; //titles
$c-denim: #0f74c7;
$c-bigStone: #1a2e47;
$c-prussianBlue: #002b50; //btn primary
$c-chathamsBlue: #174275;
$c-bismark-light: #4b6986;
$c-bismark: #436989;
$c-blueZodiac: #0b1f3a; //primary
$c-seance: #8d248f; //links in tekst
$c-black: #000000;
